import React from "react";
import "../css/main.css"

export default function PrivacyPolicy() {
  return (
    <div className="padding-privacy">
      <h1>Política de Privacidad</h1>
      <h2>Introducción</h2>
      <p>
        Bienvenido a Bajan, una aplicación que proporciona información en tiempo
        real sobre transporte público, rutas de viaje, horarios de autobuses y
        trenes, y mapas de transporte urbano. Nos comprometemos a proteger su
        privacidad y garantizar que su información personal esté segura. Esta
        Política de Privacidad explica cómo recopilamos, usamos, divulgamos y
        protegemos su información.
      </p>

      <h2>Información que Recopilamos</h2>
      <h3>Información Personal</h3>
      <p>Podemos recopilar la siguiente información personal:</p>
      <ul>
        <li>Nombre</li>
        <li>Dirección de correo electrónico</li>
        <li>Número de teléfono</li>
        <li>Ubicación geográfica en tiempo real (con su consentimiento)</li>
      </ul>
      <h3>Información No Personal</h3>
      <p>También recopilamos información no personal que puede incluir:</p>
      <ul>
        <li>Información del dispositivo (modelo, sistema operativo, etc.)</li>
        <li>Dirección IP</li>
        <li>Historial de búsqueda y navegación dentro de la aplicación</li>
        <li>Datos de uso y estadísticas</li>
      </ul>
      <h2>Uso de la información</h2>
      <p>Usamos la información recopilada para los siguientes propósitos:</p>
      <ul>
        <li>Proveer y mejorar nuestros servicios</li>
        <li>Personalizar la experiencia del usuario</li>
        <li>Responder a consultas y ofrecer soporte</li>
        <li>Enviar notificaciones y actualizaciones sobre la aplicación</li>
        <li>
          Analizar y monitorear el uso de la aplicación para mejorar su
          rendimiento
        </li>
      </ul>
      <h2>Divulgación de la Información</h2>
      <p>
        No vendemos, alquilamos ni compartimos su información personal con
        terceros, excepto en las siguientes circunstancias:
      </p>
      <ul>
        <li>
          Con proveedores de servicios que nos ayudan a operar y mejorar nuestra
          aplicación (estos proveedores están sujetos a estrictas obligaciones
          de confidencialidad)
        </li>
        <li>Cuando sea requerido por ley o en respuesta a un proceso legal</li>
        <li>
          Para proteger nuestros derechos, propiedad y seguridad, así como los
          de nuestros usuarios
        </li>
      </ul>
      <h2>Seguridad de la Información</h2>
      <p>
        Implementamos medidas de seguridad razonables para proteger su
        información personal contra el acceso no autorizado, la alteración,
        divulgación o destrucción. Sin embargo, ninguna medida de seguridad es
        completamente infalible y no podemos garantizar la seguridad absoluta de
        su información.
      </p>
      <h2>Retención de la Información</h2>
      <p>
        Retendremos su información personal solo durante el tiempo que sea
        necesario para cumplir con los propósitos para los que fue recopilada, o
        según lo requiera la ley.
      </p>
      <h2>Derechos del Usuario</h2>
      <p>
        Usted tiene el derecho de acceder, corregir o eliminar su información
        personal recopilada por la aplicación. Para ejercer estos derechos, por
        favor contáctenos a través de los medios proporcionados en esta
        política.
      </p>
      <h2>Cambios a esta Política de Privacidad</h2>
      <p>
        Podemos actualizar esta Política de Privacidad ocasionalmente para
        reflejar cambios en nuestras prácticas o por razones legales. Le
        notificaremos sobre cualquier cambio significativo a través de la
        aplicación o por otros medios apropiados.
      </p>
      <h2>Contacto</h2>
      <p>
        Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad,
        puede contactarnos a través del correo electrónico:
        privacidad@bajan.app.
      </p>
      <h2>Fecha de Vigencia</h2>
      <p>
        Esta Política de Privacidad es efectiva a partir del 18 de Junio del
        2024
      </p>

      <p>
        Con esta Política de Privacidad, buscamos garantizar que los usuarios de
        Bajan comprendan cómo se maneja su información y cómo se protege su
        privacidad al utilizar nuestra aplicación.
      </p>
    </div>
  );
}
