import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  AccessTime,
  Analytics,
  CalendarMonth,
  Feed,
  Notifications,
  PhoneAndroid,
} from "@mui/icons-material";

const items = [
  {
    icon: <AccessTime />,
    title: "Monitoreo en Tiempo Real",
    description:
      "Sigue la ubicación exacta de los vehículos en tiempo real. Mantente informado con horarios y tiempos de llegada actualizados al minuto.",
  },
  {
    icon: <CalendarMonth />,
    title: "Planificación de Rutas Inteligente",
    description:
      "Encuentra las rutas más rápidas y convenientes basadas en las condiciones del tráfico. Recibe sugerencias de rutas alternativas en caso de retrasos o interrupciones.",
  },
  {
    icon: <Notifications />,
    title: "Notificaciones y Alertas Personalizadas",
    description:
      "Recibe notificaciones sobre cualquier cambio o retraso en tu ruta. Configura alertas personalizadas para mantenerte seguro y informado.",
  },
  {
    icon: <Feed />,
    title: "Seguridad y Confianza",
    description:
      "Accede a información sobre protocolos y medidas de seguridad en el transporte. Usa las herramientas integradas para respuestas rápidas en situaciones de emergencia.",
  },
  {
    icon: <PhoneAndroid />,
    title: "Interfaz Amigable y Fácil de Usar",
    description:
      "Navega fácilmente por la aplicación con una interfaz diseñada para todos. Encuentra la información que necesitas de manera rápida y sencilla.",
  },
  {
    icon: <Analytics />,
    title: "Análisis y Estadísticas",
    description:
      "Accede a análisis y estadísticas sobre tus viajes y el rendimiento del transporte público. Genera informes personalizados para mejorar tu experiencia de viaje.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Destacados
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Las Mejores Funcionalidades de Nuestra Aplicación de Monitoreo de
            Transporte Público
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
